// useMobileCheck.js
import { useState, useEffect } from 'react';

function useMobileCheck() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1000);
    };

    handleResize(); // Initial check on component mount
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect only runs once on mount

  return isMobileOrTablet;
}

export default useMobileCheck;
