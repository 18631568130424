import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginForm from "./LoginForm";
import Button from "../../ui/Button";
import login from "../../translations/login.json";
import LoadingSpinner from "../../ui/LoadingSpinner";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { LoginContext } from "./LoginProvider";
import Layout from "../../ui/Layout";
import { BASE_URL } from "../../utils/utils";
import useMobileCheck from "../../hooks/useMobileCheck";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const isMobileOrTablet = useMobileCheck();

  const [showToolTip, setShowToolTip] = useState(false);

  const {
    username,
    setUsername,
    password,
    setPassword,
    branches,
    isLoading,
    isSubmitDisabled,
    handleLogin,
    authenticated,
  } = useContext(LoginContext);

  const clearLocalStorage = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("url");
    localStorage.removeItem("expirytime");
    localStorage.removeItem("selectedBranch");
  };

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const url = localStorage.getItem("url");
    const curr = Date.now();
    const expirytime = localStorage.getItem("expirytime");

    const fetchData = async () => {
      if (showSuccessMessage) {
        toast.success(login.messages.logout);
      }

      if (window.location.search.includes("removeToken=true")) {
        // Clear local storage and show success message only once
        clearLocalStorage();
        setShowSuccessMessage(true);

        try {
          const url = new URL(`${BASE_URL}/logout`);
          const response = await fetch(url, {
            method: "POST",
            credentials: "include", // Include cookies in the request
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error("Logout request failed");
          }
        } catch (error) {
          console.error("Logout failed:", error.message); // Log error message
        }
      } else if (authToken && url && expirytime >= curr) {
        // Redirect only if the conditions are met
        window.location.href = url;
        setLoading(true);
      }
    };

    fetchData();
  }, [showSuccessMessage, isMobileOrTablet]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Layout>
      <form className="mb-4" onSubmit={handleLogin}>
        <LoginForm
          username={username}
          password={password}
          setUsername={setUsername}
          setPassword={setPassword}
        />
        <Button
          disabled={isSubmitDisabled}
          isLoading={isLoading}
          loadingText={login.loading.text}
        >
          {login.button.login}
        </Button>
      </form>
      {authenticated && branches.length > 1 ? null : (
        <div className="flex flex-col items-center">
          <Link to="/forgot-password" className="text-blue-dark">
            {login.links.forgotpassword}?
          </Link>

          <div className="flex items-center text-blue-dark mt-2 relative">
            <Link
              to="/magic-link-login"
              className="flex items-center text-blue-dark ml-1"
            >
              {login.links.magiclink}
            </Link>

            <span
              className="ml-1 text-blue-light cursor-pointer relative"
              onClick={() => setShowToolTip(!showToolTip)} // Toggle display of tooltip
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="question-mark-icon text-xl"
              />

              {showToolTip && (
                <>
                  {isMobileOrTablet ? (
                    <div className="absolute bottom-[calc(100%+0.5rem)] left-[-5rem] -translate-x-[50%]  w-auto">
                      <div
                        className="bottom-full shadow-xl border-t-4 border-blue-light right-0 rounded-t-lg bg-white
                     py-1  text-black w-[20rem]"
                      >
                        <p className="p-4">
                          Wir senden Dir einen magische Link per E-Mail zu. Wenn
                          Du auf den Link klickst, wirst Du auf direktem Wege
                          angemeldet, ohne dass Du Deine Zugangsdaten eingeben
                          musst.
                        </p>
                        <svg
                          className="absolute left-0 top-full h-5 w-full text-blue-light"
                          x="0px"
                          y="0px"
                          viewBox="0 0 255 255"
                        >
                          <polygon
                            className="fill-current"
                            points="0,0 127.5,127.5 255,0"
                          />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <div className="tooltip">
                      <div className="border-b-4 shadow-xl border-blue-light rounded-b-lg bg-white py-1 text-black w-96 absolute left-[2.3rem] top-[-3rem]">
                        <svg
                          className="absolute right-full top-[2.5rem] h-5 w-auto text-blue-light -ml-4"
                          x="0px"
                          y="0px"
                          viewBox="0 0 255 255"
                        >
                          <polygon
                            className="fill-current"
                            points="255,0 128,127.5 255,255"
                          />
                        </svg>

                        <p className="p-4 text-l">
                          Wir senden Dir einen magische Link per E-Mail zu. Wenn
                          Du auf den Link klickst, wirst Du auf direktem Wege
                          angemeldet, ohne dass Du Deine Zugangsdaten eingeben
                          musst.
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </span>
          </div>

          <Link to="https://www.digital-identity.cc/" className="text-blue-dark mt-2 text-sm"
          target="_blank" rel="noopener noreferrer"
          >
          Zurück zur Webseite
          </Link>
        </div>
      )}
    </Layout>
  );
};

export default Login;
