import React, { useState } from "react";
import InputField from "../../ui/InputField";
import login from "../../translations/login.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const LoginForm = ({ username, password, setUsername, setPassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <InputField
        id="username"
        label={login.labels.username}
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder={login.placeholders.username}
        required
      />
      <div className="relative">
        <InputField
          id="password"
          label={login.labels.password}
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={login.placeholders.password}
          required
        />
        <FontAwesomeIcon
          icon={showPassword ? faEyeSlash : faEye}
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-12 transform -translate-y-2/4 cursor-pointer"
        />
      </div>
    </>
  );
};

export default LoginForm;
