import React, { useEffect } from "react";
import { Cookies } from "react-cookie-consent";

const loadThirdPartyScripts = () => {
  if (!document.getElementById("hs-script-loader")) {
    const script = document.createElement("script");
    script.src = "//js.hs-scripts.com/19505760.js";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";
    document.body.appendChild(script);
  }
};

const removeThirdPartyScripts = () => {
  const script = document.getElementById("hs-script-loader");
  if (script) {
    script.remove();
  }
};

const CookieConsentManager = ({ showConsent, setShowConsent }) => {
  useEffect(() => {
    const consentGiven = localStorage.getItem("cookieConsent");
    if (!consentGiven) {
      setShowConsent(true);
    } else if (consentGiven === "accepted") {
      loadThirdPartyScripts();
    }
  }, [setShowConsent]);

  const handleAccept = () => {
    console.log("Cookies accepted");
    localStorage.setItem("cookieConsent", "accepted");
    setShowConsent(false);
    loadThirdPartyScripts();
  };

  const handleDecline = () => {
    console.log("Cookies declined");
    localStorage.setItem("cookieConsent", "declined");
    Cookies.remove("_ga");
    Cookies.remove("_gid");
    setShowConsent(false);
    removeThirdPartyScripts();
    window.location.reload();
  };

  return (
    <>
      {showConsent && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75"></div>
          <div className="bg-white p-6 rounded-lg shadow-lg z-50 max-w-screen-sm mx-auto">
            <h2 className="text-2xl font-bold mb-4">Individuelle Privatsphäre-Einstellungen</h2>
            <p className="mb-3">
              Wir verwenden Cookies und ähnliche Technologien auf unserer
              Website und verarbeiten personenbezogene Daten von dir (z.B.
              IP-Adresse), um z.B. Inhalte und Anzeigen zu personalisieren,
              Medien von Drittanbietern einzubinden oder Zugriffe auf unsere
              Website zu analysieren. Die Datenverarbeitung kann auch erst in
              Folge gesetzter Cookies stattfinden. Wir teilen diese Daten mit
              Dritten, die wir in den Privatsphäre-Einstellungen benennen.
            </p>
            <p className="mb-5">
              Die Datenverarbeitung kann mit deiner Einwilligung oder auf Basis
              eines berechtigten Interesses erfolgen, dem du in den
              Privatsphäre-Einstellungen widersprechen kannst. Du hast das
              Recht, nicht einzuwilligen und deine Einwilligung zu einem
              späteren Zeitpunkt zu ändern oder zu widerrufen. Weitere
              Informationen zur Verwendung deiner Daten findest du in unserer
              Datenschutzerklärung.
            </p>
            <div className="flex justify-end">
              <button
                className="bg-blue-light text-white py-2 px-4 rounded mr-2 w-full"
                onClick={handleAccept}
              >
                Akzeptieren
              </button>
              <button
                className="bg-blue-dark text-white py-2 px-4 rounded w-full"
                onClick={handleDecline}
              >
                Weiter ohne Einwilligung
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsentManager;
