import Logo from "./Logo";

function Layout({ children }) {
  return (
    <div className="bg-white p-4 md:p-8 rounded-lg max-w-md w-full">
      <Logo />
      {children}
    </div>
  );
}

export default Layout;