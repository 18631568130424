import { createContext } from "react";
import useLogin from "./useLogin";
import { useNavigate } from "react-router-dom";

const LoginContext = createContext();

function LoginProvider({ children }) {
  const navigate = useNavigate();
  const {
    username,
    setUsername,
    password,
    setPassword,
    selectedBranch,
    setSelectedBranch,
    branches,
    isLoading,
    isSubmitDisabled,
    handleLogin,
    authenticated,
    setAuthenticated,
    isMobileOrTablet,
    setIsMobileOrTablet,
    showMobileAppModal,
    setShowMobileAppModal,
    modalData,
    setModalData,
    handleMobileAppModalAction,
    redirectToBranch,
  } = useLogin(navigate);
  return (
    <LoginContext.Provider
      value={{
        username,
        setUsername,
        password,
        setPassword,
        selectedBranch,
        setSelectedBranch,
        branches,
        isLoading,
        isSubmitDisabled,
        handleLogin,
        authenticated,
        setAuthenticated,
        isMobileOrTablet,
        setIsMobileOrTablet,
        showMobileAppModal,
        setShowMobileAppModal,
        modalData,
        setModalData,
        handleMobileAppModalAction,
        redirectToBranch,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export { LoginProvider, LoginContext };
