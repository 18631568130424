import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../ui/Logo";
import InputField from "../../ui/InputField";
import Button from "../../ui/Button";

import { toast } from "react-toastify";

import { BASE_URL } from "../../utils/utils";
import forgotPassword from "../../translations/forgotPassword.json";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const response = await fetch(`${BASE_URL}/forgotpassword/${username}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success(forgotPassword.messages.success);
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData.error);
        toast.success(forgotPassword.messages.success);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(forgotPassword.messages.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-4 md:p-8 mx-4 md:mx-auto rounded-lg max-w-md w-full">
        <Logo />
        <h4 className="text-xl font-semibold mb-4 text-center">
          {forgotPassword.title.text}
        </h4>
        <p className="mb-4 text-center">{forgotPassword.title.desc}</p>
        <form className="mb-4" onSubmit={handleForgotPassword}>
          <InputField
            id="username"
            label={forgotPassword.labels.username}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={forgotPassword.placeholders.username}
            required
          />

          <Button isLoading={isLoading} loadingText="Bearbeiten...">
            {forgotPassword.button.forgotpassword}
          </Button>
        </form>
        <Link to="/" className="text-blue-light text-center">
          {forgotPassword.link.back}
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
