import React, { useContext, useEffect, useState } from "react";
import login from "../../translations/login.json";
import Button from "../../ui/Button";
import { LoginContext } from "./LoginProvider";
import Layout from "../../ui/Layout";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/utils";
import useMobileCheck from "../../hooks/useMobileCheck";

const BranchSelection = () => {
  const { isLoading, selectedBranch, setSelectedBranch, redirectToBranch } =
    useContext(LoginContext);

  const isMobileOrTablet = useMobileCheck();

  const [sessionData, setSessionData] = useState({
    branches: [],
    login_key: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch session data when component mounts
    const fetchSessionData = async () => {
      try {
        const url = new URL(`${BASE_URL}/session`);
        const response = await fetch(url, {
          method: "GET",
          credentials: "include", // Use GET method
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch session data");
        }
        const responseData = await response.json();
        setSessionData({
          branches: responseData.branches || [],
          login_key: responseData.login_key || "",
        });
      } catch (error) {
        navigate("/");
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessionData();
  }, [navigate]);

  const { branches } = sessionData;

  const handleLoginbranch = async (e) => {
    e.preventDefault();
    if (isMobileOrTablet) {
      if (selectedBranch) {
        localStorage.setItem("selectedBranch", selectedBranch);
        navigate("/modal");
      }
    }

    if (!isMobileOrTablet && selectedBranch) {
      localStorage.setItem("selectedBranch", selectedBranch);
      redirectToBranch(sessionData);
    }
  };
  if (branches.length === 1) {
    navigate("/");
  }

  return (
    <Layout>
      <form className="mb-4" onSubmit={handleLoginbranch}>
        <div className="mb-4">
          <p className="mb-4 text-center">{login.branch.title}</p>
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="branch"
          >
            {login.branch.label}
          </label>
          <select
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required focus:ring-2 focus:ring-inset focus:ring-blue-dark mb-4"
            id="branch"
            value={selectedBranch}
            onChange={(e) => setSelectedBranch(e.target.value)}
          >
            <option value="">{login.branch.select}</option>
            {branches.map((branch) => (
              <option key={branch} value={branch}>
                {branch}
              </option>
            ))}
          </select>
          <Button isLoading={isLoading} loadingText={login.loading.text}>
            {login.branch.button}
          </Button>
        </div>
      </form>
    </Layout>
  );
};

export default BranchSelection;
