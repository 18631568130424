import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import Login from "./features/login/Login";
import ForgotPassword from "./features/forgotpassword/ForgotPassword";
import MagicLinkLogin from "./features/magiclink/MagicLinkLogin";
import BranchSelection from "./features/login/BranchSelection";
import { LoginProvider } from "./features/login/LoginProvider";
import Modal from "./features/login/Modal";
import Instruction from "./features/login/Instruction";
import NotFound from "./ui/NotFound";
import CookieConsentBanner from "./cookie/CookieConsentBanner";
import Footer from "./ui/Footer";
import useThirdPartyScripts from "./hooks/useThirdPartyScripts";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.min.css";

const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});

function App() {
  const [showConsent, setShowConsent] = useState(false);

  useThirdPartyScripts();

  const handleManageConsent = () => {
    setShowConsent(true);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="flex flex-col items-center w-full">
        <Router>
          <LoginProvider>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/magic-link-login" element={<MagicLinkLogin />} />
              <Route path="/branch" element={<BranchSelection />} />
              <Route path="/modal" element={<Modal />} />
              <Route path="/instruction" element={<Instruction />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <ToastContainer position="top-center" transition={bounce} />
            <Footer onManageConsent={handleManageConsent} />
            <CookieConsentBanner
              showConsent={showConsent}
              setShowConsent={setShowConsent}
            />
          </LoginProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
