import React from "react";

const Button = ({ onClick, disabled, isLoading, loadingText, children }) => (
  <button
    className={`bg-blue-light hover:bg-blue-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-2 ${
      disabled ? "opacity-50 cursor-not-allowed" : ""
    }`}
    type="submit"
    onClick={onClick}
    disabled={disabled}
  >
    {isLoading ? (
      <div className="flex items-center justify-center">
        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-600"></div>
        <span className="ml-2">{loadingText}</span>
      </div>
    ) : (
      children
    )}
  </button>
);

export default Button;
