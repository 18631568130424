import { useEffect } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';

const useThirdPartyScripts = () => {
  useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent) {
      const script = document.createElement('script');
      script.src = '//js.hs-scripts.com/19505760.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';
      document.body.appendChild(script);

    }
  }, []);
};

export default useThirdPartyScripts;
