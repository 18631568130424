// api.js
import { BASE_URL } from "../utils/utils";

export const loginUser = async (username, password) => {
  try {
    const response = await fetch(`${BASE_URL}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ login: username, password }),
    });

    const data = await response.json();

    if (response.ok && data.success) {
      return data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error during login");
  }
};
