import { useState } from "react";

import { toast } from "react-toastify";

import { loginUser as apiLogin } from "../../api/apiLogin";
import login from "../../translations/login.json";
import useMobileCheck from "../../hooks/useMobileCheck";
import { Link } from "react-router-dom";

const useLogin = (navigate) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const isSubmitDisabled = !username.trim() || !password.trim() || isLoading;
  const [showMobileAppModal, setShowMobileAppModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const isMobileOrTablet = useMobileCheck();

  const encodeBase64 = (str) => {
    return btoa(str);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const encodedUsername = encodeBase64(username);
    const encodedPassword = encodeBase64(password);
    if (isSubmitDisabled) {
      toast.error(login.messages.error);
      return;
    }
    setIsLoading(true);

    try {
      const data = await apiLogin(encodedUsername, encodedPassword);
      if (data.success) {
        toast.success(login.messages.success);
        const urlParams = new URLSearchParams(window.location.search);
        const redirectURL = urlParams.get("redirect");

        if (redirectURL) {
          window.location.href = redirectURL;
          return;
        }
        if (data.branches && data.branches.length > 1) {
          setBranches(data.branches);
          setAuthenticated(true);
          navigate("/branch");
        }
        localStorage.setItem("authToken", data.token);
        const expirytime = Date.now() + 2 * 60 * 60 * 1000;
        localStorage.setItem("expirytime", expirytime);
       

        if (isMobileOrTablet) {
          setModalData(data);

          if (data.branches && data.branches.length <= 1) {
            setAuthenticated(true);
            navigate("/modal");
          }
        } else {
          redirectToBranch(data);
        }
      } else {
        toast.error(login.messages.error);
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      toast.error(login.messages.error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMobileAppModalAction = (action, data) => {
    if (action === "redirect-to-app") {
      setIsLoading(true);
      const selectedBranch = localStorage.getItem("selectedBranch");
      let url;

      if (data.branches.length > 1 && selectedBranch) {
        const loginURLWithBranch = `https://${selectedBranch}-${data.login_key.replace(
          /^https:\/\//,
          ""
        )}`;
        const urlObject = new URL(loginURLWithBranch);
        console.log(urlObject);
        url = `talentlms://${urlObject.hostname}`;
      }
      if (data.branches.length === 1) {
        const branchname = data.branches[0];
        const loginURLWithBranch = `talentlms://${branchname}-${data.login_key.replace(
          /^https:\/\//,
          ""
        )}`;
        const urlObject = new URL(loginURLWithBranch);
        url = `talentlms://${urlObject.hostname}`;
      }

      if (data.branches.length === 0) {
        const logginUrl = data.login_key;
        const loginapp = `https://${logginUrl.replace(/^https:\/\//, "")}`;
        const urlObject = new URL(loginapp);
        url = `talentlms://${urlObject.hostname}`;
      }
      window.location.href = url;
      const redirectCheckDelay = 3000;
      const redirectCheckTimeout = setTimeout(() => {
        console.error("Redirect failed: App is not installed.");
        toast.error(
          <>
            Leider hast Du unsere App noch nicht installiert.
            <Link to="instruction" className="text-blue-light">
              Bitte folge zunächst unserer Anleitung zur Installation unserer
              App.
            </Link>
          </>
        );
      }, redirectCheckDelay);

      const onVisibilityChange = () => {
        clearTimeout(redirectCheckTimeout);
        document.removeEventListener("visibilitychange", onVisibilityChange);
      };
      document.addEventListener("visibilitychange", onVisibilityChange);
      
    } else {
      // Continue in the browser
      toast.success("Weiterleiten...");
      redirectToBranch(data);
    }
  };

  const redirectToBranch = (data) => {
    const selectedBranch = localStorage.getItem("selectedBranch");
    if (data.branches.length > 1 && selectedBranch) {
      const loginURLWithBranch = `https://${selectedBranch}-${data.login_key.replace(
        /^https:\/\//,
        ""
      )}`;

      // Create a new URL object
      const urlObject = new URL(loginURLWithBranch);
      const baseURL = `${urlObject.protocol}//${urlObject.hostname}`;
      window.location.href = loginURLWithBranch;

      localStorage.setItem("url", baseURL);
    }

    if (data.branches.length === 1) {
      const branchname = data.branches[0];
      const loginURLWithBranch = `https://${branchname}-${data.login_key.replace(
        /^https:\/\//,
        ""
      )}`;

      const urlObject = new URL(loginURLWithBranch);

      // Reconstruct the base URL
      const baseURL = `${urlObject.protocol}//${urlObject.hostname}`;
      window.location.href = loginURLWithBranch;

      localStorage.setItem("url", baseURL);
    }

    if (data.branches.length === 0) {
      const logginUrl = data.login_key;

      const urlObject = new URL(logginUrl);

      // Reconstruct the base URL
      const baseURL = `${urlObject.protocol}//${urlObject.hostname}`;
      window.location.href = logginUrl;

      localStorage.setItem("url", baseURL);
    }
  };

  return {
    username,
    setUsername,
    password,
    setPassword,
    selectedBranch,
    setSelectedBranch,
    branches,
    isLoading,
    isSubmitDisabled,
    handleLogin,
    authenticated,
    setAuthenticated,
    isMobileOrTablet,
    showMobileAppModal,
    setShowMobileAppModal,
    modalData,
    setModalData,
    handleMobileAppModalAction,
    redirectToBranch,
  };
};

export default useLogin;
