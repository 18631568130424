import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import insImage from "../../img/App-domain.png";
import loginImg from "../../img/App-login.png";
import iosImg from "../../img/ios.png";
import androidImg from "../../img/android.png";
import Layout from "../../ui/Layout";

import instruction from "../../translations/instructions.json";

import { BASE_URL } from "../../utils/utils";
import useMobileCheck from "../../hooks/useMobileCheck";

function Instruction() {
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const appDownloadLink = isIOS
    ? "https://apps.apple.com/de/app/talentlms/id1063795268"
    : "https://play.google.com/store/apps/details?id=com.talentlms.android&hl=de";

  const navigate = useNavigate();

  const isMobileOrTablet = useMobileCheck();

  const [modalData, setmodalData] = useState({
    branches: [],
    login_key: "",
  });

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const url = new URL(`${BASE_URL}/session`);
        const response = await fetch(url, {
          method: "GET",
          credentials: "include", // Use GET method
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch session data");
        }
        const data = await response.json();       
        setmodalData({
          branches: data.branches || [],
          login_key: data.login_key || "",
        }); 
      } catch (error) {
        navigate("/");
        console.error("Error fetching session data:", error);
      }
    };

    fetchSessionData();
  }, [navigate, isMobileOrTablet]);

  const data = modalData;

  let domain;

  if (data.branches && data.branches.length > 1) {
    const selectedBranch = localStorage.getItem("selectedBranch");
    const loginURLWithBranch = `https://${selectedBranch}.${data.login_key.replace(
      /^https:\/\//,
      ""
    )}`;
    const urlObject = new URL(loginURLWithBranch);
    domain = `${urlObject.protocol}//${urlObject.hostname}`;
  }

  if (data.branches.length === 1) {
    const branchname = data.branches[0];
    const loginURLWithBranch = `https://${branchname}.${data.login_key.replace(
      /^https:\/\//,
      ""
    )}`;

    const urlObject = new URL(loginURLWithBranch);
    domain = `${urlObject.protocol}//${urlObject.hostname}`;
  }

  if (data.branches.length === 0) {
    let loginUrl = data.login_key;
    domain = loginUrl.split("/").slice(0, 3).join("/");
  }

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(domain);
    setCopied(true);
    // Reset the copied state after a certain period (e.g., 2 seconds)
    setTimeout(() => setCopied(false), 2000);
  };

  if (!isMobileOrTablet) {
    navigate("/");
  }
  return (
    <Layout>
      <>
        <div className="mb-4">
          <ol>
            <li className="w-full bg-blue-light p-1 text-center text-white">
              1. {instruction.steps.title}
            </li>
            <li className="py-2 mb-2 text-center">
              {instruction.steps.step1_desc} <b>„TalentLMS“</b> App:
              <Link
                to={appDownloadLink}
                className="text-blue-dark underline font-semibold mt-3"
              >
                {isIOS ? (
                  <img
                    src={iosImg}
                    alt="Step 1"
                    className="mx-auto mt-2"
                    style={{
                      maxWidth: "30%",
                      height: "auto",
                    }}
                  />
                ) : (
                  <img
                    src={androidImg}
                    alt="Step 1"
                    className="mx-auto"
                    style={{
                      maxWidth: "30%",
                      height: "auto",
                    }}
                  />
                )}
              </Link>
            </li>

            <li className="w-full bg-blue-light p-1 text-center text-white">
              2. {instruction.steps.title}
            </li>

            <li className="py-2 text-center">
              {instruction.steps.step2_desc}
              <br />
              <span className=" font-semibold text-sm">
                {domain}
                <button
                  className="mt-3 px-3 py-1 bg-gray-300 rounded cursor-pointer text-sm ml-2"
                  onClick={copyToClipboard}
                >
                  {instruction.copy.success}
                  <FontAwesomeIcon icon={faCopy} className="ml-2" />
                </button>
                {copied && (
                  <span className="text-green-500 ml-2">
                    {instruction.copy.copied}
                  </span>
                )}
                <br />
              </span>
            </li>

            <li className="py-2 text-center">
              <img
                src={insImage}
                alt="Step 2"
                className="mx-auto"
                style={{
                  maxWidth: "50%",
                  height: "auto",
                }}
              />
            </li>

            <li className="w-full bg-blue-light p-1 text-center text-white mt-1">
              3. {instruction.steps.title}
            </li>
            <li className="py-2 text-center">{instruction.steps.step3_desc}</li>
            <li className="py-2">
              <img
                src={loginImg}
                alt="Step 3"
                className="mx-auto"
                style={{
                  maxWidth: "50%",
                  height: "auto",
                }}
              />
            </li>
          </ol>
        </div>
        <Link
          className="text-blue-dark underline mb-5 font-semibold"
          to="/modal"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          {instruction.links.back}
        </Link>
      </>
    </Layout>
  );
}

export default Instruction;
