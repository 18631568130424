import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../ui/Logo";
import InputField from "../../ui/InputField";
import Button from "../../ui/Button";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/utils";
import magiclink from "../../translations/magicLink.json";

const MagicLinkLogin = () => {
  const [email, setEmail] = useState("");
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleMagicLink = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const url = new URL(`${BASE_URL}/magiclink/${email}`);
      const response = await fetch(url, {
        method: "GET", // Use GET method
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
    
      if (response.ok) {
        setBranches(data.branches);
        if (data.branches.length === 0 || data.branches.length === 1) {
          toast.success(magiclink.messages.success);
        }

        if (data.branches && data.branches.length > 1 && selectedBranch) {
          const url = new URL(`${BASE_URL}/magiclink/${email}`);
          url.searchParams.append("selectedBranch", selectedBranch);

          const response = await fetch(url, {
            method: "GET", // Use GET method
            headers: {
              "Content-Type": "application/json",
            },
          });
          toast.success(magiclink.messages.success);
        }
      } else {
        const errorData = await response.json();
        console.error("Error:", errorData.error);
        toast.success(magiclink.messages.success);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.success(magiclink.messages.success);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-4 md:p-8 mx-4 md:mx-auto rounded-lg max-w-md w-full">
        <Logo />
        <h4 className="text-xl font-semibold mb-4 text-center">
          {magiclink.title.text}
        </h4>
        <p className="mb-4 text-center">{magiclink.title.desc}</p>
        <form className="mb-4" onSubmit={handleMagicLink}>
          <InputField
            id="email"
            label={magiclink.labels.username}
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={magiclink.placeholders.username}
            required
          />
          {branches.length > 1 && (
            <select
              className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline required focus:ring-2 focus:ring-inset focus:ring-blue-dark mb-4"
              id="branch"
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
            >
              <option value="">Pflegdienst auswählen</option>
              {branches.map((branch) => (
                <option key={branch.name} value={branch.name}>
                  {branch.name}
                </option>
              ))}
            </select>
          )}

          <Button isLoading={isLoading} loadingText="Bearbeiten...">
            {magiclink.button.magiclink}
          </Button>
        </form>
        <Link to="/" className="text-blue-light">
          {magiclink.link.back}
        </Link>
      </div>
    </div>
  );
};

export default MagicLinkLogin;
