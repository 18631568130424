import brandImage from "../DMA.svg";
function Logo() {
  return (
    <img
      src={brandImage}
      alt="Brand Logo"
      className="mx-auto mb-8"
      style={{ maxWidth: "60%", height: "auto" }}
    />
  );
}

export default Logo;
