import { Link } from "react-router-dom";
import Layout from "./Layout";

import forgotPassword from "../translations/forgotPassword.json";

function NotFound() {
  return (
    <Layout>
      <p className="text-center">
        Leider existiert die Seite nicht auf der Du gerade gelandet bist.
      </p>
      <Link to="/" className="text-blue-light text-center block mt-4">
        {forgotPassword.link.back}
      </Link>
    </Layout>
  );
}

export default NotFound;
